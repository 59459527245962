require('Modernizr');

import Form from '@searchtrends/webform-processor';
// window.axios = require('axios');
const axios = require('axios/dist/browser/axios.cjs'); // browser commonJS bundle (ES2017)
window.axios = axios;

try {
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

require('./jquery.autoellipsis-1.0.10.min');

try {
    Modernizr.on('webp', function (result) {
        if (result) {
            document.querySelector('body').classList.add('webp');
        } else {
            document.querySelector('body').classList.remove('no-webp');
        }
    });
} catch (e) {
    document.querySelector('body').classList.remove('no-webp');
}

window.hideGoogleMaps = function () {
    document.cookie = "showGoogleMaps=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";

    location.reload();
}

window.allowGoogleMaps = function () {
    document.cookie = "showGoogleMaps=" + Math.floor(Date.now() / 1000) + "; path=/";
}

window.loadMapsJs = function() {
    let script = document.createElement('script');

    script.src = 'https://maps.googleapis.com/maps/api/js?key=' + process.env.MIX_GOOGLE_MAPS_API_KEY;

    script.async = true;
    script.defer = true;

    document.head.appendChild(script);
    script.onload = () => {
        initMap();
    }
}

let forms = document.querySelectorAll('form[method="post"]:not(.no-ajax)');
forms.forEach(function (cf) {
    cf.addEventListener('submit', function (e) {
        e.preventDefault();

        let form = new Form(cf);
        form.post(cf.action)
            .then(response => { console.log(response); });
    });
});
